import React from "react";
import Redirect from "./Redirect";

type IProps = {
  condition: boolean;
  redirectTo: string;
};

const ProtectRoute: React.FC<IProps> = ({
  condition,
  redirectTo,
  children,
}) => {
  return condition ? <>{children}</> : <Redirect to={redirectTo} />;
};

export default ProtectRoute;
