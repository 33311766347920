import { Contract_Type } from "@milize/common/types";
import { SETTING_FIELD } from "@milize/common/constants";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const homeSelector = (state: RootState) => state.home;

export const contractsByTypesSelector = createSelector(
  homeSelector,
  (homeState) => homeState.contractsByTypes
);

export const contractsByTypeSelector = (contractType: Contract_Type) =>
  createSelector(homeSelector, (homeState) =>
    homeState.contractsByTypes.find(
      (contractsByType) => contractsByType.type.id === contractType.id
    )
  );

export const companiesSelector = createSelector(
  homeSelector,
  (homeState) => homeState.companies
);

export const contractTypesSelector = createSelector(
  homeSelector,
  (homeState) => homeState.contractTypes
);
export const contractsSelector = createSelector(
  homeSelector,
  (homeState) => homeState.contracts
);

export const settingsSelector = createSelector(
  homeSelector,
  (homeState) => homeState.settings
);

export const settingButtonSelector = createSelector(
  homeSelector,
  (homeState) => {
    return {
      maximumPercentOfDislike: homeState.settings.find(
        (setting) => setting.name === SETTING_FIELD.MAXIMUM_PERCENT_OF_DISLIKE
      )?.value,
      minimumNumberOfReview: homeState.settings.find(
        (setting) => setting.name === SETTING_FIELD.MINIMUM_NUMBER_OF_REVIEW
      )?.value,
    };
  }
);

export const haveNewContractSelector = createSelector(
  homeSelector,
  (homeState) => homeState.haveNewContract
);

export const modalCreateContractSelector = createSelector(
  homeSelector,
  (homeState) => homeState.modalCreateContract
);

export const currentContractSelector = createSelector(
  homeSelector,
  (homeState) => homeState.currentContract
);

export const currentContractDetailSelector = createSelector(
  homeSelector,
  (homeState) => homeState.currentContractDetail
);

export const contractsLengthSelector = createSelector(
  homeSelector,
  (homeState) =>
    homeState.contractsByTypes.reduce(
      (contractsLength, contractsByType) =>
        contractsLength + contractsByType.contracts.length,
      0
    )
);
