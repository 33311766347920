import {
  FormCreateInsurance,
  FormForgotPassword,
  FormUserMetadata,
  FormResetPassword,
  FormSignIn,
  FormSignUp,
  FormEditPassword,
} from "../types/form.type";
import {
  DEFAULT_VALUE_AGE,
  DEFAULT_VALUE_MONTHLY_LIVING_EXPENSES,
} from "./settings.constant";

export const FORM_FORGOT_PASSWORD: FormForgotPassword = {
  email: "",
};
export const FORM_EDIT_PASSWORD: FormEditPassword = {
  password: "",
  confirmPassword: "",
  newPassword: "",
};

export const FORM_SIGN_IN: FormSignIn = {
  email: "",
  password: "",
};

export const FORM_SIGN_UP: FormSignUp = {
  email: "",
  checkBox: false,
};

export const FORM_RESET_PASSWORD: FormResetPassword = {
  password: "",
  passwordConfirm: "",
};

export const FORM_CREATE_INSURANCE: FormCreateInsurance = {
  company: null,
  purpose: null,
  images: [],
};

export type InitialCreateContract = {
  company: number | null;
  images: string[];
};

export const INITIALVALUE: InitialCreateContract = {
  company: null,
  images: [],
};

export const FORM_USER_METADATA: FormUserMetadata = {
  sex: null,
  age: DEFAULT_VALUE_AGE,
  occupation: null,
  business: null,
  employmentPlaceSize: null,
  family: null,
  annualIncome: null,
  monthlyLivingExpenses: DEFAULT_VALUE_MONTHLY_LIVING_EXPENSES,
  houseForm: null,
  savingsAmount: null,
  stock: null,
  insurance: null,
  realEstate: null,
  otherAssets: null,
  desireAfterDeath: null,
  payFinancially: null,
  healthStatus: null,
  children: {
    count: null,
    details: [],
  },
  managedAssets: null,
  assetsUnderManagement: null,
};
