import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import type { Occupation } from "./types";

const occupationsAdapter = createEntityAdapter<Occupation>({
  selectId: (occupation) => occupation.id,
});

const occupationsSlice = createSlice({
  name: "occupations",
  initialState: {
    ...occupationsAdapter.getInitialState(),
    loaded: false,
  },
  reducers: {
    loadOccupations: (state, action: PayloadAction<Occupation[]>) => {
      occupationsAdapter.setAll(state, action.payload);
      state.loaded = true;
    },
  },
});

export const { loadOccupations } = occupationsSlice.actions;

const selectOccupationsState = (store: RootState) => store.occupations;

export const selectLoadedOccupations = createSelector(
  selectOccupationsState,
  (state) => state.loaded
);

export const {
  selectAll: selectOccupations,
  selectById: selectOccupation,
  selectEntities: selectOccupationEntities,
  selectIds: selectOccupationIds,
  selectTotal: selectTotalOccupations,
} = occupationsAdapter.getSelectors((state: RootState) => state.occupations);

export default occupationsSlice;
