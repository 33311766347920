import styled from "styled-components";

const ReviewCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: "Hiragino Sans";
  font-style: normal;
  margin-bottom: 16px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }

  .card-review {
    width: 100%;
    padding: 12px 12px 16px;
    background-color: #ffffff;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 8%);
    border-radius: 4px;

    .review-avatar {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & .avatar-box {
        display: flex;
        overflow: hidden;
        & > .ant-avatar {
          min-width: 48px;
          & > img {
            height: 48px;
            &:first-child {
              width: 48px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
        & > .review-title {
          padding-left: 8px;
          width: calc(100% - 48px);

          .fp-name {
            display: flex;
            align-items: center;
            /* white-space: nowrap; */
            flex-wrap: wrap;
            grid-gap: 8px 12px;
            word-break: break-word;

            span {
              &:first-child {
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.6px;
                color: ${({ theme }) => theme.colors.text.main};
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              &:nth-child(2) {
                height: 20px;
                width: 41px;
                text-align: center;
                background: ${({ theme }) => theme.colors.blue[1]};
                border-radius: 16px;

                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.6px;
                color: ${({ theme }) => theme.colors.system.white};
              }
            }
          }
          .fp-description {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.6px;
            color: ${({ theme }) => theme.colors.text.main};
            padding-top: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      & > img {
        height: 48px;
        &:nth-child(2) {
        }
      }

      /* &:nth-child(2) {
          // margin-top: 8px;
        } */
    }

    .attention-orange {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.6px;
      color: ${({ theme }) => theme.colors.orange.text};
      margin: 12px 0;
      word-break: break-word;
    }

    .attention-blue {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.6px;
      color: ${({ theme }) => theme.colors.navy[100]};
      margin: 12px 0;
      word-break: break-word;
    }

    .review-content.comment-short {
      .review-comment {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        .comment-full {
          text-overflow: ellipsis;
        }
      }

      .review-profile {
        display: none;
      }
    }

    .review-content {
      text-align: justify;
      .review-comment {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 170%;
        letter-spacing: 0.6px;
        color: ${({ theme }) => theme.colors.text.main};

        .comment-full {
          word-break: break-word;
        }
      }

      .review-profile {
        margin-bottom: 12px;
        position: relative;
        background: ${({ theme }) => theme.colors.back.gray};
        padding: 8px;
        .profile-title {
          padding-bottom: 6px;
          .hidden {
            height: 14px;
            width: 14px;
            background: ${({ theme }) => theme.colors.orange[50]};
            top: 8px;
            left: 8px;
            border-radius: 2px;
            margin-top: -6px;
            position: absolute;
          }

          span {
            &:nth-child(2) {
              position: relative;
              padding-left: 6px;
              font-weight: 400;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.6px;
              color: ${({ theme }) => theme.colors.gray[2]};
            }
          }
        }

        .profile-content {
          font-weight: 400;
          font-size: 12px;
          line-height: 170%;
          letter-spacing: 0.6px;
          color: ${({ theme }) => theme.colors.gray[2]};
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .review-expand {
        margin-top: 12px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.6px;
        color: ${({ theme }) => theme.colors.text.link};
        text-align: right;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.6px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          color: ${({ theme }) => theme.colors.navy[100]};

          img {
            margin-left: 8px;
            transform: rotate(180deg);
          }
        }
      }

      .review-shrink {
        margin-top: 12px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.6px;
        color: ${({ theme }) => theme.colors.text.link};
        text-align: right;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.6px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          color: ${({ theme }) => theme.colors.navy[100]};
          img {
            margin-left: 8px;
          }
        }
      }

      .review-contact {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        .ant-btn {
          display: none;
        }
      }
    }
  }
`;

const ReviewCardsWrapper = styled.div`
  width: 100%;
  .review-cards {
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.back.gray},
      ${({ theme }) => theme.colors.system.white}
    );
    padding: 16px;
    border-top-width: 24px;
    border-bottom-width: 24px;
    width: 100%;
  }

  .btn-review {
    margin: 0 24px;
    width: 100%;
    .ant-btn {
      display: none;
    }
  }
`;

export { ReviewCardWrapper, ReviewCardsWrapper };
