import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import type { Contract } from "./types";

const contractsAdapter = createEntityAdapter<Contract>({
  selectId: (contract) => contract.id,
});

const contractsSlice = createSlice({
  name: "contracts",
  initialState: contractsAdapter.getInitialState<{
    loaded: boolean;
    contractCreated: Contract | null;
    iv: string | null;
    content: string | null;
  }>({
    loaded: false,
    contractCreated: null,
    iv: null,
    content: null,
  }),
  reducers: {
    saveContractCreated(state, action: PayloadAction<{ contract: Contract }>) {
      state.contractCreated = action.payload.contract;
    },
    loadContracts(
      state,
      action: PayloadAction<{
        // contracts: Contract[];
        iv: string;
        content: string;
      }>
    ) {
      const { iv, content } = action.payload;
      // contractsAdapter.setAll(state, contracts);
      state.iv = iv;
      state.content = content;
    },
    clearCreatedContract(state) {
      state.contractCreated = null;
    },
    clearContentAndIv(state) {
      state.iv = null;
      state.content = null;
    },
  },
});

export const {
  saveContractCreated,
  loadContracts,
  clearCreatedContract,
  clearContentAndIv,
} = contractsSlice.actions;

const selectContractsState = (store: RootState) => store.contracts;

export const selectLoadedContracts = createSelector(
  selectContractsState,
  (state) => state.loaded
);

export const selectContent = createSelector(
  selectContractsState,
  (state) => state.content
);

export const selectIv = createSelector(
  selectContractsState,
  (state) => state.iv
);

export const selectCreatedContract = createSelector(
  selectContractsState,
  (state) => state.contractCreated
);

export const {
  selectAll: selectContracts,
  selectById: selectContract,
  selectEntities: selectContractEntities,
  selectIds: selectContractIds,
  selectTotal: selectTotalContracts,
} = contractsAdapter.getSelectors((state: RootState) => state.contracts);

export default contractsSlice;
