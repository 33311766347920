import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "features/auth/slice";
import companiesSlice from "features/companies/slice";
import contractTypesSlice from "features/contract-types/slice";
import contractsSlice from "features/contracts/slice";
import homeSlice from "features/home/slice";
import livesSlice from "features/lives/slice";
import notificationSlice from "features/notification/slice";
import occupationsSlice from "features/occupations/slice";
import _merge from "lodash/merge";
import _omit from "lodash/omit";
import { persistReducer, persistStore, PersistConfig } from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// const saveSubsetFilter = createFilter("auth", ["emailRegister", "email"]);
const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
  whitelist: ["auth", "contracts"],
  transforms: [
    //
    // saveSubsetFilter,
    createFilter("auth", []),
    createFilter("contracts", []),
  ],
  stateReconciler: autoMergeLevel2,
};

const contractPersistConfig = {
  key: "contracts",
  storage: storageSession,
  whitelist: [
    //
    "contractCreated",
    "iv",
    "content",
  ],
  // transforms: [saveSubsetFilterContract],
};

const authPersistConfig = {
  key: "auth",
  storage: storageSession,
  whitelist: [
    //
    "emailRegister",
    "email",
    "password",
  ],
  // transforms: [saveSubsetFilterContract],
};

const appReducer = combineReducers({
  [authSlice.name]: persistReducer(authPersistConfig, authSlice.reducer),
  [homeSlice.name]: homeSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [occupationsSlice.name]: occupationsSlice.reducer,
  [livesSlice.name]: livesSlice.reducer,
  [companiesSlice.name]: companiesSlice.reducer,
  [contractTypesSlice.name]: contractTypesSlice.reducer,
  [contractsSlice.name]: persistReducer(
    contractPersistConfig,
    contractsSlice.reducer
  ),
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  switch (action.type) {
    case authSlice.actions.logout.type:
      return appReducer(
        _merge(_omit(state, ["notification"]), {
          notification: notificationSlice.getInitialState(),
        }),
        action
      );
    default:
      return appReducer(state, action);
  }
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof appReducer>;

export default store;
