import React from "react";
import ButtonWrapper from "./styled";

type IProps = {
  text: string;
  icon?: string;
  dark?: boolean;
  link?: boolean;
  logout?: boolean;
  loading?: boolean;
  disabled?: boolean;
  light?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button: React.FC<IProps> = ({
  text,
  icon,
  dark = false,
  link = false,
  logout = false,
  loading = false,
  disabled = false,
  light = false,
  handleClick,
}) => {
  return (
    <ButtonWrapper
      className={`${
        link
          ? "button-link"
          : dark
          ? "button-dark"
          : logout
          ? "button-logout"
          : light
          ? "button-white"
          : "button-light"
      }`}
      onClick={handleClick}
      loading={loading}
      disabled={disabled}
    >
      {icon && <img src={icon} alt={icon} />}

      <p>{text}</p>
    </ButtonWrapper>
  );
};

export default Button;
