import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "@milize/common/types";

type InitialState = {
  map: Record<string | number, Notification>;
  loaded: boolean;
};

const initialState: InitialState = {
  map: {},
  loaded: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    loadNotifications(
      state,
      action: PayloadAction<{ notifications: Notification[] }>
    ) {
      const map: Record<string | number, Notification> = {};

      for (const notification of action.payload.notifications) {
        map[notification.id] = notification;
      }

      state.map = map;
      state.loaded = true;
    },
    receivedNotification(
      state,
      action: PayloadAction<{ notification: Notification }>
    ) {
      state.map[action.payload.notification.id] = action.payload.notification;
    },
    loadMoreNotifications(
      state,
      action: PayloadAction<{ notifications: Notification[] }>
    ) {
      const map: Record<string | number, Notification> = {};

      for (const notification of action.payload.notifications) {
        map[notification.id] = notification;
      }

      state.map = { ...state.map, ...map };
    },
  },
});

export const {
  loadNotifications,
  receivedNotification,
  loadMoreNotifications,
} = notificationSlice.actions;

export default notificationSlice;
