import axios from "axios";
import rest from "libs/feathers";
import { useState } from "react";
import { API_ENDPOINTS } from "../constants/api.constant";
import { getFileExt } from "../utils/files";

type State = {
  loading: boolean;
  keyPath: string | null;
  url: string | null;
};

export const createFileUrl = async (
  fileExt: string,
  fileType: string,
  file: any,
  email?: string
) => {
  const { url, keyPath } = await rest.service(API_ENDPOINTS.FILES).create({
    fileExt: fileExt || file.name.split(".").pop(),
    fileType: fileType || file.type,
    email,
  });
  return { url, keyPath };
};

export const uploadFileToUrl = async ({
  url,
  fileType,
  file,
  handleUploadProgress,
}: {
  url: string;
  fileType: string;
  file: any;
  id?: string;
  handleUploadProgress?: (percent: any) => void;
}) => {
  return await axios.request({
    method: "put",
    url,
    headers: { "Content-Type": fileType },
    data: file,
    onUploadProgress: handleUploadProgress,
  });
};

export const getFileUrl = async (keyPath: string, email?: string) => {
  return await rest
    .service(API_ENDPOINTS.FILES)
    .find({ query: { pathObject: keyPath, email } });
};

export default function useFiles() {
  const [state, setState] = useState<State>({
    loading: false,
    keyPath: null,
    url: null,
  });
  async function onUpload(file: any) {
    setState((previous) => ({ ...previous, loading: true }));

    try {
      const fileExt = getFileExt(file.name || "");

      const fileType = file.type;

      const { url, keyPath } = await createFileUrl(fileExt, fileType, file);

      await uploadFileToUrl({ url, fileType, file });

      const { url: fileURL } = await getFileUrl(keyPath);

      setState((previous) => ({
        ...previous,
        loading: false,
        keyPath,
        url: fileURL,
      }));
    } catch (error) {
      setState((previous) => ({ ...previous, loading: false }));
    }

    return false;
  }

  return { ...state, onUpload };
}
