import { User, UserMetadata } from "@milize/common/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  loggedInUser: null | User;
  emailResend: null | string;
  emailRegister: null | string;
  email: null | string;
  password: null | string;
  hasPassword: boolean;
  idUser: number | null;
};

const initialState: InitialState = {
  loggedInUser: null,
  emailResend: null,
  emailRegister: null,
  email: null,
  password: null,
  hasPassword: false,
  idUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<{ user: User }>) {
      state.loggedInUser = action.payload.user;
      state.emailResend = null;
    },
    logout(state) {
      state.emailRegister = null;
      state.password = null;
      state.email = null;
      state.hasPassword = false;
      state.idUser = null;
    },
    setEmailResend(state, action: PayloadAction<string>) {
      state.emailResend = action.payload;
    },
    setLoggedInUserMetadata(state, action: PayloadAction<UserMetadata>) {
      if (state.loggedInUser) {
        state.loggedInUser.metadata = action.payload;
      }
    },
    changeEmailSuccess(state, action: PayloadAction<{ newEmail: string }>) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.loggedInUser!.email = action.payload.newEmail;
    },

    emailRegister(state, action: PayloadAction<string | null>) {
      state.emailRegister = action.payload;
    },

    saveEmailQuestion(state, action: PayloadAction<string | null>) {
      state.email = action.payload;
    },

    loadAuth(
      state,
      action: PayloadAction<{ email: string; hasPassword: boolean; id: number }>
    ) {
      const { email, hasPassword, id } = action.payload;
      state.email = email;
      state.hasPassword = hasPassword;
      state.idUser = id;
    },
    saveEmail(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email;
    },
    savePassword(state, action: PayloadAction<{ password: string }>) {
      state.password = action.payload.password;
    },
    saveIdUser(state, action: PayloadAction<{ id: number }>) {
      state.idUser = action.payload.id;
    },
  },
});

export default authSlice;

export const {
  changeEmailSuccess,
  loginSuccess,
  logout,
  setEmailResend,
  setLoggedInUserMetadata,
  emailRegister,
  savePassword,
  loadAuth,
  saveEmail,
  saveEmailQuestion,
  saveIdUser,
} = authSlice.actions;
