import { clearContracts } from "features/home/slice";
import { selectPassword } from "./../../features/auth/selectors";
import { API_ENDPOINTS } from "@milize/common/constants";
import { selectEmail } from "features/auth/selectors";
import { loadAuth } from "features/auth/slice";
import { loadCompanies } from "features/companies/slice";
import { loadContractTypes } from "features/contract-types/slice";
import { loadLives } from "features/lives/slice";
import { loadOccupations } from "features/occupations/slice";
import rest from "libs/feathers";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getSessionId } from "utils/sessions";

export type ReceivedProps = Record<string, never>;

const useHook = (props: ReceivedProps) => {
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const password = useSelector(selectPassword);
  const [loading, setLoading] = useState(!!email);

  useEffect(() => {
    if (!password) {
      dispatch(clearContracts());
    }
    if (!email) {
      return;
    }

    (async () => {
      try {
        const sid = getSessionId();
        const res = await rest.service(API_ENDPOINTS.DIAGNOSTICS).find({
          query: { email, sid: sid || undefined },
        });

        if (res) {
          batch(() => {
            dispatch(
              loadAuth({
                ...res,
              })
            );
          });
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, email, password]);

  useEffect(() => {
    (async () => {
      try {
        const lives = await rest.service(API_ENDPOINTS.LIVES).find({
          query: {
            $limit: -1,
          },
        });

        const [occupations, companies, contractTypes] = await Promise.all(
          [
            API_ENDPOINTS.OCCUPATIONS,
            API_ENDPOINTS.COMPANIES,
            API_ENDPOINTS.CONTRACT_TYPES,
          ].map((endpoint: string) =>
            rest.service(endpoint).find({
              query: {
                $limit: -1,
              },
            })
          )
        );

        batch(() => {
          dispatch(loadOccupations(occupations));
          dispatch(loadLives(lives));
          dispatch(loadCompanies(companies));
          dispatch(loadContractTypes(contractTypes));
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error", error);
      }
    })();
  }, [dispatch]);

  return {
    ...props,
    loading,
    location,
  };
};

export type Props = ReturnType<typeof useHook>;

export default useHook;
