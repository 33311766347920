import {
  Company,
  ContractMetadata,
  DataFee,
  DataPrice,
  DataRule,
  DataTag,
} from "@milize/common/types";

import EXPAND_GRAY_ICON from "assets/images/expand-gray-icon.svg";
import EXPAND_NAVY_ICON from "assets/images/expand-navy-icon.svg";
import LIKE_GRAY_ICON from "assets/images/like-gray-icon.svg";
import LIKE_NAVY_ICON from "assets/images/like-navy-icon.svg";
import REVIEw_GRAY_ICON from "assets/images/review-gray-icon.svg";
import REVIEw_ORANGE_ICON from "assets/images/review-orange-icon.svg";

export const getAssetsContractCard: (
  likePercentage?: number,
  reviewPercentage?: number,
  isConverted?: boolean
) => {
  themeColor: "normal" | "gray" | "like" | "review" | "light";
  icons: {
    likeIcon: string;
    reviewIcon: string;
    expandIcon: string;
  };
} = (likePercentage, reviewPercentage, isConverted) => {
  if (isConverted) {
    if (likePercentage === 0 && reviewPercentage === 0) {
      return {
        themeColor: "light",
        icons: {
          likeIcon: LIKE_GRAY_ICON,
          reviewIcon: REVIEw_GRAY_ICON,
          expandIcon: EXPAND_NAVY_ICON,
        },
      };
    }
    if (likePercentage === 0) {
      return {
        themeColor: "review",
        icons: {
          likeIcon: LIKE_GRAY_ICON,
          reviewIcon: REVIEw_ORANGE_ICON,
          expandIcon: EXPAND_NAVY_ICON,
        },
      };
    }
    if (reviewPercentage === 0) {
      return {
        themeColor: "like",
        icons: {
          likeIcon: LIKE_NAVY_ICON,
          reviewIcon: REVIEw_GRAY_ICON,
          expandIcon: EXPAND_NAVY_ICON,
        },
      };
    }
    return {
      themeColor: "normal",
      icons: {
        likeIcon: LIKE_NAVY_ICON,
        reviewIcon: REVIEw_ORANGE_ICON,
        expandIcon: EXPAND_NAVY_ICON,
      },
    };
  } else {
    return {
      themeColor: "gray",
      icons: {
        likeIcon: LIKE_GRAY_ICON,
        reviewIcon: REVIEw_GRAY_ICON,
        expandIcon: EXPAND_GRAY_ICON,
      },
    };
  }
};

export const getDetailContract = (metadata: ContractMetadata | null) => {
  return {
    dataFees: [
      {
        name: "detai.insurance_fees.name.monthly_premium",
        age: null,
        ageStage: "",
        unit: "detail.insurance_fees.unit.en",
        fees: metadata?.insuranceFee?.value,
        default: "detail.insurance_fees.type.price.en",
        stage: metadata?.insuranceFee?.type,
      },
      {
        name: "detai.insurance_fees.name.total_premium",
        age: metadata?.warrantyPeriod?.value.split(","),
        ageStage: metadata?.warrantyPeriod?.type,
        fees: metadata?.totalPremiumPaid?.value,
        unit: "detail.insurance_fees.unit.en",
        default: "detail.insurance_fees.info.age25",
        stage: metadata?.totalPremiumPaid?.type,
      },
      {
        name: "detai.insurance_fees.name.refunds",
        age: [
          metadata?.warrantyPeriod?.value
            .split(",")
            .filter((_, index) => index > 0)[0],
        ],
        ageStage: "",
        fees: metadata?.cancellationRefund?.value,
        unit: "detail.insurance_fees.unit.en",
        default: "detail.insurance_fees.info.age60",
        stage: metadata?.cancellationRefund?.type,
      },
    ] as DataFee[],
    dataRules: [
      {
        title: "detail.rules.title.warranty_period",
        info: metadata?.warrantyPeriod?.value,
        stage: metadata?.warrantyPeriod?.type,
      },
      {
        title: "detail.rules.title.insured_person",
        info: metadata?.protectedPerson,
        stage: "",
      },
      {
        title: "detail.rules.title.premium_payer",
        info: metadata?.contractor,
        stage: "",
      },
      {
        title: "detail.rules.title.insurance_recipients",
        info: metadata?.recipient,
        stage: "",
      },
    ] as DataRule[],
    dataPrice: [
      {
        name: "detail.guarantee_contract.name.death_benefit",
        values: {
          type: metadata?.deathBenefit?.type,
          value: metadata?.deathBenefit?.value,
        },
      },
      {
        name: "detail.guarantee_contract.name.hospitalize",
        values: {
          once: metadata?.hospiltalization?.once,
          daily: metadata?.hospiltalization?.daily,
        },
      },
      {
        name: "detail.guarantee_contract.name.specific_disease",
        values: {
          once: metadata?.specificDisease?.once,
          daily: metadata?.specificDisease?.daily,
        },
      },
      {
        name: "detail.guarantee_contract.name.surgery",
        values: {
          time: metadata?.operation,
        },
      },
      {
        name: "detail.guarantee_contract.name.outpatient",
        values: {
          time: metadata?.outpatient,
        },
      },
      {
        name: "detail.guarantee_contract.name.advance_medical",
        values: {
          total: metadata?.advanceMedical,
        },
      },
      {
        name: "detail.paymentPeriod.label",
        values: {
          total: metadata?.paymentPeriod,
        },
      },
      {
        name: "detail.guarantee_contract.name.femal_disease",
        values: {
          once: metadata?.femaleDisease?.once,
          daily: metadata?.femaleDisease?.daily,
        },
      },
      {
        name: "detail.guarantee_contract.name.cancer",
        values: {
          once: metadata?.cancer?.once,
          daily: metadata?.cancer?.daily,
        },
      },
      {
        name: "detail.guarantee_contract.name.inability_work",
        values: {
          monthly: metadata?.unavaiable,
        },
      },
      {
        name: "detail.hindrance.label",
        values: {
          once: metadata?.hindrance?.once,
          daily: metadata?.hindrance?.daily,
        },
      },
      {
        name: "detail.guarantee_contract.name.nursing",
        values: {
          once: metadata?.nursing?.once,
          daily: metadata?.nursing?.daily,
        },
      },
    ] as DataPrice[],
    dataTags: [
      {
        name: "detail.summary_tag.labels.name1",
        color: Boolean(
          metadata?.deathBenefit?.value || metadata?.deathBenefit?.type
        ),
      },
      {
        name: "detail.summary_tag.labels.name2",
        color: Boolean(
          metadata?.hospiltalization?.daily || metadata?.hospiltalization?.once
        ),
      },
      {
        name: "detail.summary_tag.labels.name3",
        color: Boolean(metadata?.operation),
      },
      {
        name: "detail.summary_tag.labels.name4",
        color: Boolean(metadata?.outpatient),
      },
      {
        name: "detail.summary_tag.labels.name5",
        color: Boolean(metadata?.cancer?.daily || metadata?.cancer?.once),
      },
      {
        name: "detail.summary_tag.labels.name6",
        color: Boolean(metadata?.unavaiable),
      },
      {
        name: "detail.summary_tag.labels.name9",
        color: Boolean(metadata?.nursing?.daily || metadata?.nursing?.once),
      },
      {
        name: "detail.summary_tag.labels.name10",
        color: Boolean(metadata?.advanceMedical),
      },
      {
        name: "detail.summary_tag.labels.name11",
        color: Boolean(
          metadata?.femaleDisease?.daily || metadata?.femaleDisease?.once
        ),
      },
      {
        name: "detail.summary_tag.labels.name12",
        color: Boolean(metadata?.storage),
      },
      {
        name: "detail.summary_tag.labels.name13",
        color: Boolean(
          metadata?.specificDisease?.daily || metadata?.specificDisease?.once
        ),
      },
      {
        name: "detail.guarantee.label",
        color: Boolean(metadata?.guarantee),
      },

      {
        name: "detail.hindrance.label",
        color: Boolean(metadata?.hindrance?.daily || metadata?.hindrance?.once),
      },
    ] as DataTag[],
  };
};

export const isFullSizeCharacter = (value: string) => {
  for (let i = 0; i < value?.length; i++) {
    const code = value.charCodeAt(i);
    if (
      (code >= 0x0020 && code <= 0x1fff) ||
      (code >= 0xff61 && code <= 0xff9f)
    ) {
      return false;
    }
  }
  return !!(value && "string" === typeof value) && true;
};

export const convertFullwidthToHalfwidth = (value: string) =>
  value.replace(/[\uff01-\uff5e]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });

export const filterSearchCompany = (
  companies: Company[],
  search: string,
  value: number | null
) =>
  companies.filter((item) => {
    if (search) {
      return (
        convertFullwidthToHalfwidth(item.name.toLowerCase()).includes(
          convertFullwidthToHalfwidth(search.toLowerCase())
        ) || item.id === value
      );
    } else {
      return true;
    }
  });

export const getExperienceYears = (experienceYears: string) =>
  new Date().getFullYear() - new Date(experienceYears).getFullYear() || 0;

export async function getFileFromUrl(
  url: string,
  name: string,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}
