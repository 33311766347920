import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import type { Live } from "./types";

const livesAdapter = createEntityAdapter<Live>({
  selectId: (live) => live.id,
});

const livesSlice = createSlice({
  name: "lives",
  initialState: {
    ...livesAdapter.getInitialState(),
    loaded: false,
  },
  reducers: {
    loadLives: (state, action: PayloadAction<Live[]>) => {
      livesAdapter.addMany(state, action.payload);
      state.loaded = true;
    },
  },
});

export const { loadLives } = livesSlice.actions;

const selectLivesState = (store: RootState) => store.lives;

export const selectLoadedLives = createSelector(
  selectLivesState,
  (state) => state.loaded
);

export const {
  selectAll: selectLives,
  selectById: selectLive,
  selectEntities: selectLiveEntities,
  selectIds: selectLiveIds,
  selectTotal: selectTotalLives,
} = livesAdapter.getSelectors((state: RootState) => state.lives);

export default livesSlice;
