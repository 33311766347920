/* eslint-disable react-hooks/exhaustive-deps */
import { getIvSelector } from "./../contracts/selectors";
import {
  emailRegisterSelector,
  selectHasPassword,
  selectPassword,
} from "features/auth/selectors";

import {
  API_ENDPOINTS,
  COMMENT_FIELD,
  COMMENT_STATUS,
  COMPANY_FIELD,
  CONTRACTS_PAGINATION_SIZE,
  CONTRACT_FIELD,
  CONTRACT_STATUS,
  CONTRACT_TYPE_FIELD,
  CREATE_CONTRACT_FIELD,
  IMAGES_LIMIT_NUMBER,
  IMAGES_TYPE_ALLOWED,
  InitialCreateContract,
  RESOURCES_PAGINATION_SIZE,
  REVIEWS_PAGINATION_SIZE,
} from "@milize/common/constants";
import { USER_RELATIVE_ROUTES } from "@milize/common/constants/routes.constant";
import {
  createFileUrl,
  getFileUrl,
  uploadFileToUrl,
} from "@milize/common/hooks/useFiles";
import {
  Comment,
  Company,
  Contract,
  Contract_Type,
  FormCreateInsurance,
  MessageModalProps,
} from "@milize/common/types";
import { getFileExt } from "@milize/common/utils/files";
import ERROR_ICON from "assets/images/error-icon.svg";
import { loggedInUserSelector } from "features/auth/selectors";
import { savePassword } from "features/auth/slice";
import {
  loadContracts,
  saveContractCreated,
  selectContent,
  selectIv,
} from "features/contracts/slice";
import { useFormikContext } from "formik";
import useAppSearchParams from "hooks/useAppSearchParams";
import rest from "libs/feathers";
import _isUndefined from "lodash/isUndefined";
import _omitBy from "lodash/omitBy";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {
  contractsSelector,
  currentContractSelector,
  settingsSelector,
} from "./selectors";
import {
  deleteContract,
  listContracts,
  loadMoreContracts,
  readCommentsCurrentContract,
  setCommentsCurrentContract,
  setCompanies,
  setContractTypes,
  setCurrentContract,
  setCurrentContractDetail,
  setHaveNewContract,
  setSettings,
} from "./slice";
import { getContentSelector } from "features/contracts/selectors";
import { contractCreatedSelector } from "features/contracts/selectors";
import { getFileFromUrl } from "./util";
import { getSessionId } from "utils/sessions";

export const useImageCreateContract = () => {
  const { messages } = useIntl();
  const email: any = useSelector(emailRegisterSelector);
  const location: any = useLocation();
  const emailUpdate = location?.state?.owner?.email;
  const { setFieldValue, setFieldTouched } =
    useFormikContext<FormCreateInsurance>();
  const [currentImages, setCurrentImages] = useState<
    {
      id: string;
      file: File;
      percentLoaded: number;
    }[]
  >([]);
  const [processImages, setProcessImages] = useState<
    {
      id: string;
      url: string;
    }[]
  >([]);

  const [isOpenWarming, setIsOpenWarning] = useState(false);
  const [error, setError] = useState<MessageModalProps | null>(null);
  const contractCreated: any = useSelector(contractCreatedSelector);

  useEffect(() => {
    (async () => {
      if (contractCreated?.photos?.length) {
        const photosURL: { id: string; file: File; url: string }[] =
          await Promise.all(
            contractCreated?.photos.map(async (photo: string) => {
              const newId = uuidV4();
              const file = await getFileFromUrl(photo, newId);

              return {
                id: newId,
                file: file,
                url: photo,
              };
            })
          );

        // setExistsImages(photosURL);

        setCurrentImages((currentImages) => [
          ...photosURL.map((photo) => {
            return {
              id: photo.id,
              file: photo.file,
              percentLoaded: 100,
            };
          }),
          ...currentImages,
        ]);

        setProcessImages((processImages) => [
          ...photosURL.map((photo) => {
            return {
              id: photo.id,
              url: photo.url,
            };
          }),
          ...processImages,
        ]);
      }
    })();
  }, [contractCreated]);

  useEffect(() => {
    const urls: string[] = [];

    currentImages.forEach((currentImage) => {
      const existImage = processImages.find(
        (processImage) => processImage.id === currentImage.id
      );
      if (existImage) urls.push(existImage.url);
    });

    if (urls.length > 0) {
      setFieldTouched(CREATE_CONTRACT_FIELD.IMAGES, true);
    }

    setFieldValue(CREATE_CONTRACT_FIELD.IMAGES, urls);

    if (currentImages.length < processImages.length) {
      setProcessImages(
        processImages.filter((processImage) =>
          currentImages.find(
            (currentImage) => currentImage.id === processImage.id
          )
        )
      );
    }
  }, [currentImages, processImages, setFieldValue, setFieldTouched]);

  const handleAddImage: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    if (e.target.files?.length) {
      const newImages: {
        id: string;
        file: File;
      }[] = [];

      for (let i = 0; i < e.target.files.length; i++) {
        if (IMAGES_TYPE_ALLOWED.includes(e.target.files[i].type))
          newImages.push({
            id: uuidV4(),
            file: e.target.files[i],
          });
      }

      for (let i = 0; i < e.target.files.length; i++) {
        if (!IMAGES_TYPE_ALLOWED.includes(e.target.files[i].type)) {
          setError({
            message: messages["notification.error.upload_image"].toString(),
            icon: ERROR_ICON,
            onCancel: () => {
              setError((error) => error && { ...error, visible: false });
            },
            visible: true,
          });
          return;
        }
      }

      if (newImages.length > IMAGES_LIMIT_NUMBER) {
        setError({
          message: messages["upload.more.than.picture"].toString(),
          icon: ERROR_ICON,
          onCancel: () => {
            setError((error) => error && { ...error, visible: false });
          },
          visible: true,
        });
        return;
      }

      setCurrentImages([
        ...currentImages,
        ...newImages.map(({ id, file }) => ({
          id,
          file,
          percentLoaded: 0,
        })),
      ]);

      setIsOpenWarning(false);
      setError(null);

      Promise.all(
        newImages.map(async ({ file, id }) => {
          try {
            const fileExt = getFileExt(file.name);
            const fileType = file.type;
            const { url, keyPath } = await createFileUrl(
              fileExt,
              fileType,
              file,
              emailUpdate ? emailUpdate : email
            );
            await uploadFileToUrl({
              url,
              fileType,
              file,
              handleUploadProgress: (percent: any) => {
                setCurrentImages((currentImages) => {
                  const currentImage = currentImages.find(
                    (currentImage) => currentImage.id === id
                  );

                  if (currentImage) {
                    currentImage.percentLoaded =
                      (percent.loaded / percent.total) * 100;
                  }

                  return currentImages;
                });
              },
            });
            const { url: fileURL } = await getFileUrl(
              keyPath,
              emailUpdate ? emailUpdate : email
            );

            setProcessImages((processImages) => [
              ...processImages,
              {
                id: id,
                url: fileURL,
              },
            ]);
          } catch (error: any) {
            const errorMessage = error?.errors?.[0]?.message || error?.message;
            setError({
              message: messages[errorMessage] || errorMessage,
              icon: ERROR_ICON,
              onCancel: () => {
                setError((error) => error && { ...error, visible: false });
              },
              visible: true,
            });
          }
        })
      );
    }
  };

  const handleRemoveImage = (id: string) => {
    const deleteImageIndex = currentImages.findIndex(
      (currentImage) => currentImage.id === id
    );
    if (deleteImageIndex >= 0) {
      const deleteImages = [...currentImages];
      deleteImages.splice(deleteImageIndex, 1);
      setCurrentImages(deleteImages);
    }
  };

  return {
    error,
    currentImages,
    isOpenWarming,
    processImages,
    setIsOpenWarning,
    handleAddImage,
    handleRemoveImage,
  };
};

export const useCreateContract = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<MessageModalProps | null>(null);

  const email = useSelector(emailRegisterSelector);

  const navigate = useNavigate();
  const handleCreateContract = async ({
    values,
    contract,
  }: {
    values: InitialCreateContract;
    contract: Contract | null;
  }) => {
    const { company, images } = values;
    const sid = getSessionId();
    try {
      setError(null);
      setLoading(true);

      if (contract) {
        await rest.service(API_ENDPOINTS.CONTRACTS).patch(contract.id, {
          companyId: company,
          photos: images,
          email: contract.owner.email,
          sid,
        });
      } else {
        const contractCreated = await rest
          .service(API_ENDPOINTS.CONTRACTS)
          .create({
            companyId: company,
            photos: images,
            email: email,
            sid,
          });
        dispatch(saveContractCreated({ contract: contractCreated }));
      }

      setLoading(false);
      dispatch(setHaveNewContract(true));
      navigate(`/${USER_RELATIVE_ROUTES.MY_PAGE}`);
    } catch (error: any) {
      setLoading(false);

      const errorMessage = error?.errors?.[0]?.message || error?.message;
      setError({
        message: messages[errorMessage] || errorMessage,
        icon: ERROR_ICON,
        onCancel: () => {
          setError((error) => error && { ...error, visible: false });
        },
        visible: true,
      });
    }
  };

  return { loading, error, handleCreateContract };
};

export const useGetPublicResources = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchPublicResources = async () => {
      try {
        Promise.all([
          (async () => {
            const { data: companies } = (await rest
              .service(API_ENDPOINTS.COMPANIES)
              .find({
                query: {
                  $limit: RESOURCES_PAGINATION_SIZE,
                  $sort: {
                    [COMPANY_FIELD.ID]: 1,
                  },
                },
              })) as { data: Company[] };
            dispatch(setCompanies(companies));
          })(),
          (async () => {
            const { data: contractTypes } = (await rest
              .service(API_ENDPOINTS.CONTRACT_TYPES)
              .find({
                query: {
                  $limit: RESOURCES_PAGINATION_SIZE,
                },
                $sort: {
                  [CONTRACT_TYPE_FIELD.ID]: 1,
                },
              })) as { data: Contract_Type[] };
            dispatch(setContractTypes(contractTypes));
          })(),
        ]);
      } catch (error: any) {
        setError(error);
      }
    };
    fetchPublicResources();
  }, [dispatch]);

  return { error };
};

export const useGetContracts = () => {
  const dispatch = useDispatch();
  // const loggedInUser = useSelector(loggedInUserSelector);
  const hasPassword = useSelector(selectHasPassword);
  const pwd = useSelector(selectPassword);
  const { messages } = useIntl();
  const saveContent = useSelector(getContentSelector);
  const saveIv = useSelector(getIvSelector);

  const [{ iv, content }] =
    useAppSearchParams<{ iv: string; content: string }>();

  const [loading, setLoading] = useState<boolean | null>(null);
  const [error, setError] = useState<MessageModalProps | null>(null);

  const email = useSelector(emailRegisterSelector);
  const [visibleModal, setVisibleModal] = useState(false);
  const toggleModal = () => {
    setVisibleModal((prev) => !prev);
  };
  useEffect(() => {
    if ((iv || content) && !pwd) {
      setVisibleModal(true);
    } else {
      setVisibleModal(false);
    }
  }, [content, iv, pwd]);
  const getIv = iv ? iv : saveIv;
  const getContent = content ? content : saveContent;
  const handleGetContract = useCallback(
    async (values?: { password: string }) => {
      const hashParams = _omitBy(
        { iv: getIv, content: getContent, sid: getSessionId() || undefined },
        _isUndefined
      );
      try {
        setError(null);
        setLoading(true);
        const dataContracts = await rest.service(API_ENDPOINTS.CONTRACTS).find({
          query: {
            ...(values?.password || pwd
              ? {
                  password: values?.password || pwd,
                }
              : {}),
            email: email,
            $sort: {
              [CONTRACT_FIELD.UPDATED_AT]: -1,
            },
            $limit: CONTRACTS_PAGINATION_SIZE,
            ...hashParams,
          },
        });
        setLoading(false);
        batch(() => {
          dispatch(
            listContracts({
              ...dataContracts,
            })
          );
          if (iv && content) {
            dispatch(loadContracts({ iv, content }));
          }

          if (values?.password) {
            dispatch(savePassword({ password: values.password }));
          }
        });
        setVisibleModal(false);
      } catch (error: any) {
        setLoading(false);
        const errorMessage = error?.errors?.[0]?.message || error?.message;
        setError({
          message: messages[errorMessage] || errorMessage,
          icon: ERROR_ICON,
          onCancel: () => {
            setError((error) => error && { ...error, visible: false });
          },
          visible: true,
        });
      }
    },
    [getIv, getContent, pwd, email, hasPassword, iv, content]
  );

  useEffect(() => {
    handleGetContract();
  }, [visibleModal, iv, content, saveContent, saveIv]);

  return { error, loading, handleGetContract, visibleModal, toggleModal };
};

export const useDeleteContract = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const pwd = useSelector(selectPassword);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<MessageModalProps | null>(null);
  const handleDeleteContract = async (
    contract: Contract,
    callback: () => void
  ) => {
    try {
      setError(null);
      setLoading(true);

      await rest
        .service(`${API_ENDPOINTS.CONTRACTS}`)
        .remove(
          contract.id,
          ...(contract.status !== CONTRACT_STATUS.REJECT
            ? [{ query: { password: pwd } }]
            : [])
        );
      setLoading(false);
      dispatch(deleteContract(contract));
      callback();
    } catch (error: any) {
      setLoading(false);
      const errorMessage = error?.errors?.[0]?.message || error?.message;

      setError({
        message: messages[errorMessage] || errorMessage,
        icon: ERROR_ICON,
        onCancel: () => {
          setError((error) => error && { ...error, visible: false });
        },
        visible: true,
      });
    }
  };

  return { loading, error, handleDeleteContract };
};

export const useGetContractDetail = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector(emailRegisterSelector);
  const password = useSelector(selectPassword);
  const { id } = useParams<{ id: string }>();
  const content = useSelector(selectContent);
  const iv = useSelector(selectIv);

  const getContractDetail = useCallback(
    async (values?: any) => {
      const pwd = values?.password || password;

      try {
        const response = await rest.service(API_ENDPOINTS.CONTRACTS).get(id, {
          query: { password: pwd, email },
        });
        batch(() => {
          dispatch(setCurrentContractDetail(response));
          if (!password) {
            dispatch(savePassword({ password: pwd }));
          }
        });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [dispatch, id, password]
  );

  useEffect(() => {
    if (!password || !content || !iv) {
      return;
    }

    getContractDetail();
  }, [getContractDetail, content, iv, password]);

  return { loading, getContractDetail, content, iv };
};

export const useReadCommentsCurrentContract = () => {
  const [error, setError] = useState<any>();
  const loggedInUser = useSelector(loggedInUserSelector);
  const currentContract = useSelector(currentContractSelector);

  const dispatch = useDispatch();
  const handleReadComments = async () => {
    if (loggedInUser && currentContract?.comments.length)
      try {
        const newComments = currentContract.comments.filter(
          (comment) => !comment.readers.includes(loggedInUser.id)
        );

        if (newComments.length) {
          await Promise.all(
            newComments.map(
              async ({ id }) =>
                await rest.service(API_ENDPOINTS.COMMENT_READERS).create({
                  commentId: id,
                })
            )
          );
          dispatch(
            readCommentsCurrentContract({
              contract: currentContract,
              userId: loggedInUser.id,
            })
          );
        }
      } catch (error: any) {
        setError(error);
      }
  };

  return { error, handleReadComments };
};

export const useGetPrivateResources = () => {
  const dispatch = useDispatch();
  const user = useSelector(loggedInUserSelector);
  const settings = useSelector(settingsSelector);

  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const getPrivateResources = async () => {
      try {
        const { data: settings } = await rest
          .service(API_ENDPOINTS.SETTINGS)
          .find();
        dispatch(setSettings(settings));
      } catch (error: any) {
        setError(error);
      }
    };
    getPrivateResources();
  }, [user, settings.length, dispatch]);

  return { error };
};

export const useGetCurrentContract = (id: number) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const [error, setError] = useState<MessageModalProps | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);

  const handleGetCurrentContract = useCallback(async () => {
    if (!isNaN(id))
      try {
        setError(null);
        setLoading(true);
        const [{ data: contract }, { data: comments, total }] =
          await Promise.all([
            (async () =>
              (await rest.service(API_ENDPOINTS.CONTRACTS).find({
                query: {
                  id: id,
                },
              })) as { data: Contract[] })(),
            (async () =>
              (await rest.service(API_ENDPOINTS.COMMENTS).find({
                query: {
                  contractId: id,
                  status: COMMENT_STATUS.APPROVED,
                },
              })) as {
                data: Comment[];
                total: number;
              })(),
          ]);
        if (contract.length)
          dispatch(
            setCurrentContract({
              ...contract[0],
              comments,
              totalComments: total,
            })
          );
        setLoading(false);
      } catch (error: any) {
        setLoading(false);

        const errorMessage = error?.errors?.[0]?.message || error?.message;
        setError({
          message: messages[errorMessage] || errorMessage,
          icon: ERROR_ICON,
          onCancel: () => {
            setError((error) => error && { ...error, visible: false });
          },
          visible: true,
        });
      }
    else setLoading(false);
  }, [dispatch, messages, id]);

  useEffect(() => {
    handleGetCurrentContract();
  }, [handleGetCurrentContract]);

  return { error, loading };
};

export const useLoadMoreContracts = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const contracts = useSelector(contractsSelector);
  const email = useSelector(emailRegisterSelector);
  const saveContent = useSelector(getContentSelector);
  const saveIv = useSelector(getIvSelector);
  const pwd = useSelector(selectPassword);
  const [{ iv, content }] =
    useAppSearchParams<{ iv: string; content: string }>();
  const getIv = iv ? iv : saveIv;
  const getContent = content ? content : saveContent;

  const handleLoadMoreContracts = async () => {
    const hashParams = _omitBy(
      { iv: getIv, content: getContent || undefined },
      _isUndefined
    );
    try {
      setLoading(true);
      const data = await rest.service(API_ENDPOINTS.CONTRACTS).find({
        query: {
          password: pwd,
          email: email,
          $sort: {
            [CONTRACT_FIELD.UPDATED_AT]: -1,
          },
          $limit: CONTRACTS_PAGINATION_SIZE,
          $skip: contracts.data.length,
          ...hashParams,
        },
      });
      dispatch(
        loadMoreContracts({
          ...data,
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setError(error);
    }
  };

  return { loading, error, handleLoadMoreContracts };
};

export const useLoadMoreCommentsCurrentContract = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const contract = useSelector(currentContractSelector);

  const handleLoadMoreComments = async () => {
    if (contract && contract.comments.length < contract.totalComments)
      try {
        setLoading(true);
        const { data: comments } = (await rest
          .service(API_ENDPOINTS.COMMENTS)
          .find({
            query: {
              contractId: contract.id,
              $sort: {
                [COMMENT_FIELD.UPDATED_AT]: -1,
              },
              $limit: REVIEWS_PAGINATION_SIZE,
              $skip: contract.comments.length,
              status: COMMENT_STATUS.APPROVED,
            },
          })) as { data: Comment[] };
        dispatch(setCommentsCurrentContract(comments));
        setLoading(false);
      } catch (error) {
        setLoading(false);

        setError(error);
      }
  };

  return { loading, error, handleLoadMoreComments };
};
