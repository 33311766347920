import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { DEFAULT_THEME } from "@milize/common/constants";
import AppLocale from "config/translation";
import store, { persistor } from "./store";
import AppRoutes from "./routes";
import "./style.css";
import jaJP from "antd/lib/locale/ja_JP";
import { ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";

const DEFAULT_LOCALE = "ja";

const App: React.FC = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={DEFAULT_THEME}>
          <ConfigProvider locale={jaJP}>
            <IntlProvider
              locale={DEFAULT_LOCALE}
              defaultLocale={DEFAULT_LOCALE}
              messages={AppLocale[DEFAULT_LOCALE].messages}
            >
              <Router>
                <AppRoutes />
              </Router>
            </IntlProvider>
          </ConfigProvider>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default App;
