import { LoadingOutlined } from "@ant-design/icons";
import { DEFAULT_THEME } from "@milize/common/constants";
import { Spin } from "antd";
import React from "react";
import LoadingWrapper from "./styled";

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24, color: DEFAULT_THEME.colors.navy[100] }}
            spin
          />
        }
      />
    </LoadingWrapper>
  );
};

export default Loading;
