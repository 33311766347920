import {
  USER_ABSOLUTE_ROUTES,
  USER_RELATIVE_ROUTES,
} from "@milize/common/constants/routes.constant";
import SHADOW_FOOTER_CONTRACTS from "assets/images/shadow-footer-contracts.svg";
import SHADOW_FOOTER_CREATE_CONTRACT from "assets/images/shadow-footer-create-contract.svg";
import SHADOW_FOOTER_REVIEWS from "assets/images/shadow-footer-reviews.svg";
import Loading from "components/Loading";
import ReviewCards from "components/ReviewCards";
import { selectEmail } from "features/auth/selectors";
import React, { lazy, memo, Suspense } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import useHook, { Props, ReceivedProps } from "./hook";
import ProtectRoute from "./ProtectRoute";
import Redirect from "./Redirect";

const CommonLayout = lazy(() => import("components/CommonLayout"));
const Contracts = lazy(() => import("features/home/components/Contracts"));
const Reviews = lazy(() => import("features/home/components/Reviews"));
const Detail = lazy(() => import("features/home/components/Detail"));
const RegisterPage = lazy(() => import("features/auth/components/Signup"));
const CreateContract = lazy(
  () => import("features/home/components/CreateContract")
);
const NotificationDetail = lazy(
  () => import("features/notification/NotificationDetail")
);
const StartPage = lazy(() => import("features/StartPage"));
const QuestionPage = lazy(() => import("features/question"));

const RoutesLayout: React.FC<Props> = ({ loading }) => {
  const email = useSelector(selectEmail);
  const getRoutes = () => [
    {
      path: USER_RELATIVE_ROUTES.HOME,
      element: (
        <ProtectRoute
          condition={Boolean(!email)}
          redirectTo={USER_ABSOLUTE_ROUTES.MY_PAGE}
        >
          <CommonLayout />
        </ProtectRoute>
      ),
      children: [
        {
          path: "",
          element: <StartPage />,
        },
        {
          path: USER_RELATIVE_ROUTES.SIGNUP,
          element: <RegisterPage />,
        },
        {
          path: USER_ABSOLUTE_ROUTES.QUESTION,
          element: <QuestionPage />,
        },
      ],
    },

    {
      path: USER_RELATIVE_ROUTES.HOME,
      element: (
        // <ProtectRoute
        //   condition={Boolean(email)}
        //   redirectTo={USER_RELATIVE_ROUTES.HOME}
        // >
        <CommonLayout isGrayBackground shadowFooter={SHADOW_FOOTER_CONTRACTS} />
        // </ProtectRoute>
      ),
      children: [
        {
          path: USER_ABSOLUTE_ROUTES.MY_PAGE,
          element: <Contracts />,
        },
      ],
    },

    {
      path: USER_RELATIVE_ROUTES.HOME,
      element: (
        // <ProtectRoute
        //   condition={Boolean(email)}
        //   redirectTo={USER_RELATIVE_ROUTES.HOME}
        // >
        <CommonLayout shadowFooter={SHADOW_FOOTER_REVIEWS} />
        // </ProtectRoute>
      ),
      children: [
        {
          path: `${USER_RELATIVE_ROUTES.CONTRACTS}/${USER_RELATIVE_ROUTES.CONTRACT_ID}`,
          element: <Reviews />,
          children: [
            {
              path: USER_RELATIVE_ROUTES.REVIEW,
              element: <ReviewCards />,
            },
            {
              path: USER_RELATIVE_ROUTES.DETAIL,
              element: <Detail />,
            },
          ],
        },
        {
          path: `${USER_RELATIVE_ROUTES.CONTRACTS}/${USER_RELATIVE_ROUTES.CONTRACT_ID}`,
          children: [
            {
              path: USER_RELATIVE_ROUTES.UPDATE,
              element: <CreateContract />,
            },
          ],
        },
        {
          path: `${USER_RELATIVE_ROUTES.NOTIFICATION}/${USER_RELATIVE_ROUTES.NOTIFICATION_ID}`,
          element: <NotificationDetail />,
        },
      ],
    },

    {
      path: USER_RELATIVE_ROUTES.HOME,
      element: (
        <ProtectRoute
          condition={Boolean(email)}
          redirectTo={USER_RELATIVE_ROUTES.HOME}
        >
          <CommonLayout
            isGrayBackground
            shadowFooter={SHADOW_FOOTER_CREATE_CONTRACT}
          />
        </ProtectRoute>
      ),
      children: [
        {
          path: USER_RELATIVE_ROUTES.CREATE,
          element: <CreateContract />,
        },
      ],
    },
    {
      path: "*",
      element: <Redirect to={USER_RELATIVE_ROUTES.HOME} />,
    },
  ];

  const routes = useRoutes(getRoutes());

  if (loading) return <Loading />;

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

const Routes: React.FC<ReceivedProps> = (props) => {
  return <RoutesLayout {...useHook(props)} />;
};

export default memo(Routes);
