import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
const contractSelector = (state: RootState) => state.contracts;

export const contractCreatedSelector = createSelector(
  contractSelector,
  (homeState) => homeState.contractCreated
);
export const getContentSelector = createSelector(
  contractSelector,
  (homeState) => homeState.content
);
export const getIvSelector = createSelector(
  contractSelector,
  (homeState) => homeState.iv
);
