import { UserOutlined } from "@ant-design/icons";
import { REVIEW_REACTION } from "@milize/common/constants";
import { Comment } from "@milize/common/types";
import { Avatar } from "antd";
import CONTENTMENT_ICON from "assets/images/contentment-icon.svg";
import EXPAND_BLUE_ICON from "assets/images/expand-more-blue-icon.svg";
import EXPAND_ORANGE_ICON from "assets/images/expand-more-orange-icon.svg";
import REVIEW_CARD_ICON from "assets/images/review-card-icon.svg";
import Button from "components/Button";
import { loggedInUserSelector } from "features/auth/selectors";
import { getExperienceYears } from "features/home/util";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ReviewCardWrapper } from "./styled";

type IProps = {
  review: Comment;
  disabledRequest: boolean;
};

const ReviewCard: React.FC<IProps> = ({ review, disabledRequest }) => {
  const { formatMessage } = useIntl();
  const [expand, setExpand] = useState(false);
  const {
    reaction,
    content,
    readers,
    owner: { bio, name, experienceYears, avatar },
    title,
  } = review;
  const loggedInUser = useSelector(loggedInUserSelector);

  const isNewArrival = useMemo(
    () => (loggedInUser && !readers.includes(loggedInUser.id)) || false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInUser]
  );

  return (
    <ReviewCardWrapper>
      <div className="card-review">
        <div className="review-avatar">
          <div className="avatar-box">
            <Avatar src={avatar} icon={<UserOutlined />} size={48} />
            <div className="review-title">
              <div className="fp-name">
                <span>{name}</span>
                {isNewArrival && (
                  <span>
                    {formatMessage({ id: "contract.labels.short_new_review" })}
                  </span>
                )}
              </div>
              <div className="fp-description">
                {formatMessage({ id: "detail.contract.experience.before" })}
                {experienceYears && getExperienceYears(experienceYears)}
                {formatMessage({ id: "detail.contract.experience.behind" })}
              </div>
            </div>
          </div>
          <img
            src={
              reaction === REVIEW_REACTION.REVIEW
                ? REVIEW_CARD_ICON
                : CONTENTMENT_ICON
            }
            alt="REVIEW_ICON"
          />
        </div>
        {title && (
          <div
            className={
              reaction === REVIEW_REACTION.REVIEW
                ? "attention-orange"
                : "attention-blue"
            }
          >
            {title}
          </div>
        )}

        <div className={`review-content ${!expand && "comment-short"}`}>
          <div className="review-comment">
            {content.split("\n").map((c, index) => (
              <div key={index} className="comment-full">
                {c}
              </div>
            ))}
          </div>
          {bio && (
            <div className="review-profile">
              <div className="profile-title">
                <div className="hidden"></div>
                <span>{formatMessage({ id: "sidebar.menu.profile" })}</span>
              </div>
              <div className="profile-content">{bio}</div>
            </div>
          )}
          {expand && (
            <div className="review-contact">
              <Button
                text={formatMessage({ id: "review.buttons.contact" })}
                disabled={disabledRequest}
              />
            </div>
          )}
          <div className={expand ? "review-expand" : "review-shrink"}>
            <span
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand
                ? formatMessage({ id: "review.labels.shrink" })
                : formatMessage({ id: "review.labels.expand" })}
              <img
                src={
                  reaction === REVIEW_REACTION.REVIEW
                    ? EXPAND_BLUE_ICON
                    : EXPAND_ORANGE_ICON
                }
                alt="EXPAND_ICON"
              />
            </span>
          </div>
        </div>
      </div>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
