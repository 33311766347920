import {
  Comment,
  Company,
  Contract,
  ContractsByType,
  ContractsList,
  ContractWithComments,
  Contract_Type,
  Setting,
} from "@milize/common/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  contractsByTypes: ContractsByType[];
  companies: Company[];
  contractTypes: Contract_Type[];
  settings: Setting[];
  haveNewContract: boolean;
  modalCreateContract: boolean;
  currentContract: ContractWithComments | null;
  currentContractDetail: Contract | null;
  contracts: ContractsList;
};

const initialState: InitialState = {
  contractsByTypes: [],
  companies: [],
  contractTypes: [],
  settings: [],
  haveNewContract: false,
  modalCreateContract: false,
  currentContract: null,
  currentContractDetail: null,
  contracts: {
    data: [],
    limit: 0,
    skip: 0,
    total: 0,
  },
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCompanies(state, action: PayloadAction<Company[]>) {
      state.companies = action.payload;
    },
    setContractTypes(state, action: PayloadAction<Contract_Type[]>) {
      state.contractTypes = action.payload;
    },
    setSettings(state, { payload }: PayloadAction<Setting[]>) {
      state.settings = payload;
    },
    setContractsByTypes(state, { payload }: PayloadAction<ContractsByType[]>) {
      state.contractsByTypes = payload;
    },
    deleteContract({ contracts }, { payload }: PayloadAction<Contract>) {
      contracts.data = contracts.data.filter(
        (contract) => contract.id !== payload.id
      );
      contracts.total = contracts.total - 1;
    },
    readCommentsCurrentContract(
      { contractsByTypes, currentContract },
      {
        payload: { contract, userId },
      }: PayloadAction<{ contract: ContractWithComments; userId: number }>
    ) {
      if (userId && currentContract) {
        currentContract.comments.forEach((comment) => {
          if (!comment.readers.includes(userId)) comment.readers.push(userId);
        });

        const contractsByType = contractsByTypes.find(
          ({ type }) => type.id === contract.purpose
        );

        if (contractsByType) {
          const updateContract = contractsByType.contracts.find(
            ({ id }) => id === currentContract.id
          );
          if (updateContract) updateContract.news = 0;
        }
      }
    },
    setHaveNewContract(state, { payload }: PayloadAction<boolean>) {
      state.haveNewContract = payload;
    },
    setModalCreateContract(state, { payload }: PayloadAction<boolean>) {
      state.modalCreateContract = payload;
    },
    setCurrentContract(
      state,
      { payload }: PayloadAction<ContractWithComments | null>
    ) {
      state.currentContract = payload;
    },
    setCurrentContractDetail(
      state,
      { payload }: PayloadAction<Contract | null>
    ) {
      state.currentContractDetail = payload;
    },
    listContracts(state, { payload }: PayloadAction<ContractsList>) {
      state.contracts = payload;
    },
    loadMoreContracts(state, { payload }: PayloadAction<ContractsList>) {
      if (!state.contracts.data.length) {
        state.contracts = {
          ...state.contracts,
          limit: payload.limit,
          skip: payload.skip,
          total: payload.total,
        };
      }
      state.contracts.data = state.contracts.data.concat(payload.data);
    },
    setCommentsCurrentContract(
      { currentContract },
      { payload }: PayloadAction<Comment[]>
    ) {
      if (currentContract) {
        currentContract.comments.push(...payload);
      }
    },
    clearContracts(state) {
      state.contracts = {
        data: [],
        limit: 0,
        skip: 0,
        total: 0,
      };
    },
  },
});

export default homeSlice;

export const {
  setCompanies,
  setContractTypes,
  setSettings,
  setContractsByTypes,
  deleteContract,
  readCommentsCurrentContract,
  setHaveNewContract,
  setCurrentContract,
  loadMoreContracts,
  listContracts,
  setCommentsCurrentContract,
  setCurrentContractDetail,
  setModalCreateContract,
  clearContracts,
} = homeSlice.actions;
