import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import type { ContractType } from "./types";

const contractTypesAdapter = createEntityAdapter<ContractType>({
  selectId: (live) => live.id,
});

const contractTypesSlice = createSlice({
  name: "contractTypes",
  initialState: {
    ...contractTypesAdapter.getInitialState(),
    loaded: false,
  },
  reducers: {
    loadContractTypes: (state, action: PayloadAction<ContractType[]>) => {
      contractTypesAdapter.addMany(state, action.payload);
      state.loaded = true;
    },
  },
});

export const { loadContractTypes } = contractTypesSlice.actions;

const selectContractTypesState = (store: RootState) => store.contractTypes;

export const selectLoadedContractTypes = createSelector(
  selectContractTypesState,
  (state) => state.loaded
);

export const {
  selectAll: selectContractTypes,
  selectById: selectContractType,
  selectEntities: selectContractTypeEntities,
  selectIds: selectContractTypeIds,
  selectTotal: selectTotalContractTypes,
} = contractTypesAdapter.getSelectors(
  (state: RootState) => state.contractTypes
);

export default contractTypesSlice;
