import { Button } from "antd";
import styled from "styled-components";

const ButtonWrapper = styled(Button)`
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  margin: 0;
  padding: 0 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.orange.text};
  gap: 10px;

  &.button-link {
    box-shadow: none;
    border: none;
    background: transparent;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.orange.text};
    }
  }

  &.button-dark {
    min-height: 48px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    background: ${({ theme }) => theme.colors.orange[100]};
    color: ${({ theme }) => theme.colors.system.white};
    border: none;
  }

  &.button-light {
    min-height: 48px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.orange.text};
    border: 1px solid ${({ theme }) => theme.colors.orange[100]};
  }

  &.button-logout {
    min-height: 48px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.navy[100]};
    border: none;
  }
  &.button-white {
    min-height: 48px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    color: ${({ theme }) => theme.colors.orange[100]};
    border: 1px solid ${({ theme }) => theme.colors.orange[100]};
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover,
  &:disabled:focus {
    background: ${({ theme }) => theme.colors.gray[6]};
    color: ${({ theme }) => theme.colors.system.white};
    box-shadow: none;
    border: none;
  }

  & > p {
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.6px;
  }
`;

export default ButtonWrapper;
