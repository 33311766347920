import { Row } from "antd";
import Button from "components/Button";
import {
  useLoadMoreCommentsCurrentContract,
  useReadCommentsCurrentContract,
} from "features/home/hooks";
import {
  currentContractDetailSelector,
  settingButtonSelector,
} from "features/home/selectors";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ReviewCard from "./ReviewCard";
import { ReviewCardsWrapper } from "./styled";

const ReviewCards: React.FC = () => {
  const { formatMessage } = useIntl();
  const contract: any = useSelector(currentContractDetailSelector);
  const { comments, likes, dislikes, totalComments } = contract;
  const { maximumPercentOfDislike, minimumNumberOfReview } = useSelector(
    settingButtonSelector
  );
  const { handleReadComments } = useReadCommentsCurrentContract();
  const { loading, handleLoadMoreComments } =
    useLoadMoreCommentsCurrentContract();

  const totalReactions = likes + dislikes;
  const likePercent = +((likes / totalReactions) * 100).toFixed(0);

  const disabledRequest =
    totalReactions < Number(minimumNumberOfReview) ||
    100 - likePercent < Number(maximumPercentOfDislike);

  useEffect(() => {
    handleReadComments();
  }, [handleReadComments]);

  return (
    <ReviewCardsWrapper>
      {Boolean(comments.length) && (
        <div className="review-cards">
          {comments.map((comment: any) => (
            <ReviewCard
              key={comment.id}
              review={comment}
              disabledRequest={disabledRequest}
            />
          ))}
        </div>
      )}

      {totalComments && comments.length < totalComments && (
        <Button
          link
          text={formatMessage({ id: "contract.labels.loadmore" })}
          loading={loading}
          handleClick={handleLoadMoreComments}
        />
      )}

      {comments.length !== 0 && (
        <Row justify="center" className="mt-32">
          <div className="btn-review">
            <Button
              dark
              text={formatMessage({ id: "review.buttons.review" })}
              disabled={disabledRequest}
            />
          </div>
        </Row>
      )}
    </ReviewCardsWrapper>
  );
};

export default ReviewCards;
