import auth from "@feathersjs/authentication-client";
import feathers from "@feathersjs/feathers";
import socketIO from "@feathersjs/socketio-client";
import { TOKEN_STORED_KEY } from "@milize/common/constants";
import io from "socket.io-client";

const API_URL = process.env.REACT_APP_API_URL as string;
const SITE = process.env.REACT_APP_SITE as string;
// socket
const feathersIO = feathers();
const socket = io(API_URL, {
  transportOptions: {
    polling: {
      withCredentials: true,
      extraHeaders: {
        Site: SITE,
      },
    },
  },
});
feathersIO.configure(
  socketIO(socket, {
    timeout: 15000,
  })
);
feathersIO.configure(
  auth({
    storageKey: TOKEN_STORED_KEY,
  })
);

export default feathersIO;
