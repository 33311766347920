import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import type { Company } from "./types";

const companiesAdapter = createEntityAdapter<Company>({
  selectId: (live) => live.id,
});

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    ...companiesAdapter.getInitialState(),
    loaded: false,
  },
  reducers: {
    loadCompanies: (state, action: PayloadAction<Company[]>) => {
      companiesAdapter.addMany(state, action.payload);
      state.loaded = true;
    },
  },
});

export const { loadCompanies } = companiesSlice.actions;

const selectCompaniesState = (store: RootState) => store.companies;

export const selectLoadedCompanies = createSelector(
  selectCompaniesState,
  (state) => state.loaded
);

export const {
  selectAll: selectCompanies,
  selectById: selectCompany,
  selectEntities: selectCompanyEntities,
  selectIds: selectCompanyIds,
  selectTotal: selectTotalCompanies,
} = companiesAdapter.getSelectors((state: RootState) => state.companies);

export default companiesSlice;
