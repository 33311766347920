import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const authSelector = (state: RootState) => state.auth;

export const loggedInUserSelector = createSelector(
  authSelector,
  (authState) => authState.loggedInUser
);

export const emailResendSelector = createSelector(
  authSelector,
  (authState) => authState.emailResend
);

export const isExistEmailSelector = createSelector(
  emailResendSelector,
  (emailResendSelector) => Boolean(emailResendSelector)
);

export const emailRegisterSelector = createSelector(
  authSelector,
  (auState) => auState.emailRegister
);

export const selectPassword = createSelector(
  authSelector,
  (state) => state.password
);

export const selectHasPassword = createSelector(
  authSelector,
  (state) => state.hasPassword
);

export const selectEmail = createSelector(authSelector, (state) => state.email);
export const selectIdUser = createSelector(
  authSelector,
  (state) => state.idUser
);
